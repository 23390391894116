/**
 * Print style file
 *

@import 'base';
@import 'config';
@import 'breakpoint';

/* ---------- Import base ---------- */
/* 32px */
/* blue */
/* red */
/* light blue */
/* blue breadcrumbs, pager */
/* ==========================================================================
   Normalize
   ========================================================================== */
*,
:after,
:before {
  box-sizing: inherit; }

/* Html & body
   ========================================================================== */
html {
  height: 100%;
  scroll-behavior: smooth;
  box-sizing: border-box; }
  @media (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto; } }

body {
  min-height: 100%;
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border: 0; }

figure {
  margin: 0; }
  figure img {
    display: block; }

svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/* ==========================================================================
   Scaffolding - Rem Calc
   ========================================================================== */
/* Strip Unit
   ========================================================================== */
/* Convert to rem
   ========================================================================== */
/* Rem Calc
   ========================================================================== */
/* ==========================================================================
   Scaffolding - Breakpoints
   ========================================================================== */
/* Next
   ========================================================================== */
/* Min
   ========================================================================== */
/* Max
   ========================================================================== */
/* Up
   ========================================================================== */
/* Down
   ========================================================================== */
/* Only
   ========================================================================== */
/* Between
   ========================================================================== */
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 550px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1100px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1150px) {
    .container {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1900px) {
    .container {
      width: 100%;
      max-width: 100%; } }

.row {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -webkit-flex-wrap: wrap; }
  @media (min-width: 280px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 550px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1100px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1150px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }
  @media (min-width: 1900px) {
    .row {
      margin-left: -1rem;
      margin-right: -1rem; } }

.col {
  position: relative;
  flex-grow: 1;
  flex-basis: 100%;
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: 1px; }
  .col.-centered {
    margin: 0 auto; }
  @media (min-width: 280px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 550px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1100px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1150px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (min-width: 1900px) {
    .col {
      padding-left: 1rem;
      padding-right: 1rem; } }

@media (min-width: 280px) {
  .col-xs-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-xs-1 {
    margin-left: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-xs-2 {
    margin-left: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-xs-3 {
    margin-left: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-xs-4 {
    margin-left: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-xs-5 {
    margin-left: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-xs-6 {
    margin-left: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-xs-7 {
    margin-left: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-xs-8 {
    margin-left: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-xs-9 {
    margin-left: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-xs-10 {
    margin-left: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-xs-11 {
    margin-left: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-xs-12 {
    margin-left: 100%; } }

@media (min-width: 550px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-sm-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-sm-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-sm-3 {
    margin-left: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-sm-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-sm-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-sm-6 {
    margin-left: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-sm-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-sm-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-sm-9 {
    margin-left: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-sm-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-sm-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-sm-12 {
    margin-left: 100%; } }

@media (min-width: 1100px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-md-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-md-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-md-3 {
    margin-left: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-md-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-md-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-md-6 {
    margin-left: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-md-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-md-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-md-9 {
    margin-left: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-md-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-md-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-md-12 {
    margin-left: 100%; } }

@media (min-width: 1150px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-lg-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-lg-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-lg-3 {
    margin-left: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-lg-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-lg-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-lg-6 {
    margin-left: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-lg-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-lg-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-lg-9 {
    margin-left: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-lg-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-lg-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-lg-12 {
    margin-left: 100%; } }

@media (min-width: 1900px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%; }
  .col-offset-xl-1 {
    margin-left: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-offset-xl-2 {
    margin-left: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .col-offset-xl-3 {
    margin-left: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-offset-xl-4 {
    margin-left: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%; }
  .col-offset-xl-5 {
    margin-left: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%; }
  .col-offset-xl-6 {
    margin-left: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%; }
  .col-offset-xl-7 {
    margin-left: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-offset-xl-8 {
    margin-left: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%; }
  .col-offset-xl-9 {
    margin-left: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%; }
  .col-offset-xl-10 {
    margin-left: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%; }
  .col-offset-xl-11 {
    margin-left: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .col-offset-xl-12 {
    margin-left: 100%; } }

.ipe-tabs [data-tab-id="revert"] a {
  display: none; }

.ipe-tabs .ipe-tab:nth-last-child(2) {
  border-right: 1px solid darkgray;
  border-top-right-radius: 9px; }

/* some updates for the panopoly widgets popup */
#modal-content .form-item > label {
  font-size: 1.2em; }

#modal-content .form-item .form-item > label,
#modal-content .form-item > label.option {
  font-size: 1em; }

#modal-content .media-widget a {
  margin-right: 1em; }

#modal-content .form-actions {
  float: none;
  width: auto;
  text-align: left;
  margin: 1em 1em 3em 0;
  clear: both; }

/* Fixes for flexbox behavior on rows */
.panels-ipe-region {
  width: 100%; }

.panels-ipe-editing .panels-ipe-region {
  float: none; }

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0; }
  ul.panels-ipe-linkbar li {
    display: inline-block; }

.manualcrop-style-button-holder {
  display: inline-block; }

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0; }
  ul.panels-ipe-linkbar li {
    display: inline-block; }

div[onclick] {
  cursor: pointer; }

.cke_editable {
  font-size: 16px;
  padding: 10px 15px; }
  .cke_editable p, .cke_editable span, .cke_editable ul {
    font-size: 16px !important; }
  .cke_editable h2 {
    color: #185C71;
    font-size: 23px; }
  .cke_editable h3 {
    color: #78BDCA;
    font-size: 18px; }
  .cke_editable ul:not([class]) {
    margin: 5px 0 30px 16px; }
    .cke_editable ul:not([class]) li:before {
      top: 10px;
      left: -15px; }
  .cke_editable a {
    cursor: pointer; }

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

html,
body {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.45;
  color: #144b5c; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "brandon-grotesque", sans-serif;
  line-height: 1.1; }
  @media (max-width: 1149px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 12px 0; } }
  @media (min-width: 1100px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 15px 0; } }
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    margin-top: 0; }

h1 {
  text-transform: uppercase;
  font-weight: 900;
  color: #185C71; }
  @media (max-width: 1149px) {
    h1 {
      font-size: 1.5rem; } }
  @media (min-width: 1100px) {
    h1 {
      font-size: 1.625rem; } }

h2 {
  font-weight: 700;
  color: #78BDCA; }
  @media (max-width: 1149px) {
    h2 {
      font-size: 1.5rem; } }
  @media (min-width: 1100px) {
    h2 {
      font-size: 1.6875rem; } }
  h2.pane-title {
    text-transform: uppercase;
    color: #185C71;
    font-size: 1.5625rem;
    font-weight: 900;
    margin-top: 0;
    max-width: calc(100% - 100px); }
  h2.grouping-title {
    color: #185C71; }

h3 {
  font-weight: 700;
  color: #185C71; }
  @media (max-width: 1149px) {
    h3 {
      font-size: 1.125rem; } }
  @media (min-width: 1100px) {
    h3 {
      font-size: 1.375rem; } }

h4 {
  font-weight: 700;
  font-size: 1.125rem; }

h5 {
  font-weight: 700;
  font-size: 1.125rem; }

h6 {
  font-weight: 700;
  font-size: 1rem; }

p {
  margin: 20px 0; }

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 20px; }

.intro-txt {
  font-size: 1rem; }
  @media (min-width: 1100px) {
    .intro-txt {
      font-size: 1.125rem; } }

a {
  color: #185C71;
  text-decoration: underline;
  transition: all 0.25s ease; }
  a:hover, a:focus {
    color: #2385a3; }

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #D6E7E9; }
  @media (min-width: 1100px) {
    hr {
      margin: 40px 0; } }

.quote {
  background-color: #ffffff;
  border: 1px solid #D6E7E9;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  margin: 0; }
  @media (max-width: 1149px) {
    .quote {
      padding: 30px; }
      .quote:before {
        top: 30px;
        left: 30px; } }
  @media (min-width: 1100px) {
    .quote {
      padding: 85px 40px 40px 40px; }
      .quote:before {
        top: 40px;
        left: 40px; } }
  .quote:before {
    content: '';
    position: absolute;
    background: url("../images/icons/application/quote-marks.svg") no-repeat;
    width: 34px;
    height: 27px; }

.quote__cite {
  display: block;
  margin-top: 10px;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.9375rem;
  font-weight: bold;
  font-style: normal; }

ul:not([class]), .download-list, .links {
  padding: 0;
  margin: 5px 0 30px 24px; }
  ul:not([class]) li, .download-list li, .links li {
    list-style: none;
    margin-bottom: 1px;
    position: relative; }
    ul:not([class]) li::before, .download-list li::before, .links li::before {
      content: "";
      position: absolute;
      top: 12px;
      left: -22px;
      display: inline-block;
      margin-right: 10px;
      width: 5px;
      height: 5px;
      background-color: #185C71;
      border-radius: 50%; }

ol:not(.fancy) {
  padding: 0;
  margin: 5px 0 30px 24px; }

ol.fancy {
  margin: 5px 0 30px 20px;
  padding: 0;
  list-style: none;
  counter-reset: li; }
  ol.fancy li {
    position: relative;
    margin-bottom: 20px;
    counter-increment: li;
    padding-left: 25px; }
    ol.fancy li:before {
      position: absolute;
      content: counter(li);
      color: #ffffff;
      background-color: #185C71;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      font-weight: bold;
      display: inline-block;
      left: -20px;
      text-align: center;
      padding-top: 2px; }

.list-reset, .bordered-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.bordered-list {
  margin: 10px 0; }
  .bordered-list li {
    padding: 15px 0;
    border-top: 1px solid #D6E7E9; }
    .bordered-list li:last-child {
      border-bottom: 1px solid #D6E7E9; }

.responsive-table {
  overflow-x: auto;
  margin: 30px 0; }

table {
  width: 100%; }
  table th, table td {
    /*border-bottom: 1px solid $border-color;
	    border-left: 1px solid $border-color;
	    border-right: 1px solid $border-color;*/
    padding: 12px 20px;
    text-align: left;
    /*&:first-child {
	    	border-left: none;
	    }
	    &:last-child {
	    	border-right: none;
	    }*/ }
  table th {
    font-weight: 600; }
  table tr th {
    background-color: #7A959D; }
    table tr th:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px; }
    table tr th:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px; }
  table tr:nth-child(even) td {
    background-color: #7A959D; }
    table tr:nth-child(even) td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px; }
    table tr:nth-child(even) td:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px; }

header,
footer,
.breadcrumb {
  display: none; }

html,
body,
p,
a,
h1 {
  color: #111111; }

a {
  border-color: #111111 !important; }
