$primary-color: #185C71; /* blue */
$secondary-color: #DE4744; /* red */
$tertiary-color: #78BDCA; /* light blue */
$quaternary-color: #7A959D; /* blue breadcrumbs, pager */

$border-color: #D6E7E9;
$text-color: #185C71;

$white: #ffffff;
$black: #111111;
$grey: #7A959D;

$yellow: #DAA32E;
$red: #DE4744;
$blue-bg: #E7F2F4;

$error-color: #EB170B;
$warning-color: #E55300;
$confirmation-color: #4EA93E;
