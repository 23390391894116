hr {
  margin: 20px 0;

  border: 0;
  border-top: 1px solid $border-color;

  @include media-breakpoint-up(md) {
    margin: 40px 0;
  }
}
