* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  font-family: $font-family--base;
  font-size: rem-calc(17);
  font-weight: $font-weight--base;
  line-height: $line-height--base;
  color: darken($text-color,5%);
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

