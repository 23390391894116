ul:not([class]), .download-list, .links {
  padding: 0;
  margin: 5px 0 30px 24px;
  li {
    list-style: none;
    margin-bottom: 1px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: -22px;
      display: inline-block;
      margin-right: 10px;
      width: 5px;
      height: 5px;
      background-color: $primary-color;
      border-radius: 50%;
    }
  }
}

ol:not(.fancy) {
  padding: 0;
  margin: 5px 0 30px 24px;
}

ol.fancy {
  margin: 5px 0 30px 20px;
  padding: 0;
  list-style: none; counter-reset: li;
  li {
    position: relative;
    margin-bottom: 20px;
    counter-increment: li;
    padding-left: 25px;
    &:before {
      position: absolute;
      content: counter(li);
      color: $white;
      background-color: $primary-color;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      font-weight: bold;
      display: inline-block;
      left: -20px;
      text-align: center;
      padding-top: 2px;
    }
  }
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bordered-list {
  @extend.list-reset;
  margin: 10px 0;

  li {
    padding: 15px 0;
    border-top: 1px solid $border-color;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }
}
