$rem-base: 16px;

$border-radius: 12px;

$transition: all .25s ease;
$transition-animation: all 1.5s ease;

$max-site-width: 1340px;
$max-content-width: 930px;
$desktop-padding: 50px;
$tablet-padding: 30px;
$phone-padding: 20px;

$grid-breakpoints: (
  xs: 280px,
  sm: 550px,
  md: 1100px,
  lg: 1150px,
  xl: 1900px
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%
);

$grid-columns-count: 12;
$grid-gutter-width-base: 2rem; /* 32px */

$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

$z-index: (
  backdrop: 401,
  menu-wrapper: 400,
  dialog: 300,
  site-logo: 403,
  messages: 403,
  language-switcher-wrapper: 402,
  main-nav-dropdown: 200,
  main-nav: 100,
  toc-nav: 99,
  main-footer: 20,
  social-side-nav: 10
);


// styleguide //
$main-content-max-width: 82.35294rem;
$main-header-height: 5.125rem;
$main-nav-height: 3.625rem;
$default-aside-width: 25rem;