h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family--headers;
  line-height: $line-height--headers;
  @include media-breakpoint-down(md) {
    margin: 12px 0;
  }
  @include media-breakpoint-up(md) {
    margin: 15px 0;
  }
  + p {
    margin-top: 0;
  }
}

h1 {
  text-transform: uppercase;
  font-weight: 900;
  color: $primary-color;
  @include media-breakpoint-down(md) {
    font-size: rem-calc(24);
  }
  @include media-breakpoint-up(md) {
    font-size: rem-calc(26);
  }
}

h2 {
  font-weight: 700;
  color: $tertiary-color;
  @include media-breakpoint-down(md) {
    font-size: rem-calc(24);
  }
  @include media-breakpoint-up(md) {
    font-size: rem-calc(27);
  }
  &.pane-title {
    text-transform: uppercase;
    color: $primary-color;
    font-size: rem-calc(25);
    font-weight: 900;
    margin-top: 0;
    max-width: calc(100% - 100px);
  }
  &.grouping-title {
    color: $primary-color;
  }
}

h3 {
  font-weight: 700;
  color: $primary-color;
  @include media-breakpoint-down(md) {
    font-size: rem-calc(18);
  }
  @include media-breakpoint-up(md) {
    font-size: rem-calc(22);
  }
}

h4 {
  font-weight: 700;
  font-size: rem-calc(18);
}

h5 {
  font-weight: 700;
  font-size: rem-calc(18);
}

h6 {
  font-weight: 700;
  font-size: rem-calc(16);
}