a {
  color: $primary-color;
  text-decoration: underline;
  transition: $transition;

  &:hover,
  &:focus {
    color: lighten($primary-color,12%);
  }
}
