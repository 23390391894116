p {
  margin: 20px 0;
}

p {
  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-top: 20px;
  }
}