.quote {
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  position: relative;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.1);
  font-size: rem-calc(16);
  margin: 0;
  @include media-breakpoint-down(md) {
    padding: 30px;
    &:before {
      top: 30px;
      left: 30px;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 85px 40px 40px 40px;
    &:before {
      top: 40px;
      left: 40px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    background: url('../images/icons/application/quote-marks.svg') no-repeat;
    width: 34px;
    height: 27px;
  }
}

.quote__cite {
  display: block;
  margin-top: 10px;
  font-family: $font-family--headers;
  font-size: rem-calc(15);
  font-weight: bold;
  font-style: normal;
}
