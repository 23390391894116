/**
 * Print style file
 *

@import 'base';
@import 'config';
@import 'breakpoint';

/* ---------- Import base ---------- */

@import "config/config";
@import 'base/normalize';
@import 'base/scaffolding/scaffolding';

@import 'typography/typography';

header,
footer,
.breadcrumb {
  display: none;
}
html,
body,
p,
a,
h1 {
  color: $black;
}
a {
  border-color: $black !important;
}
