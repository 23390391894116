.responsive-table {
	overflow-x: auto;
	margin: 30px 0;
}

table {
	width: 100%;
	th, td {
	    /*border-bottom: 1px solid $border-color;
	    border-left: 1px solid $border-color;
	    border-right: 1px solid $border-color;*/
	    padding: 12px 20px;
	    text-align: left;
	    /*&:first-child {
	    	border-left: none;
	    }
	    &:last-child {
	    	border-right: none;
	    }*/
	}
	th {
		font-weight: 600;
	}
	tr th {
		background-color: $grey;
		&:first-child {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}
		&:last-child {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
	tr {
		&:nth-child(even) td {
			background-color: $grey;
			&:first-child {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;
			}
			&:last-child {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}
		}
	}
}
