$font-family--base: "brandon-grotesque", sans-serif;
$font-family--headers: "brandon-grotesque", sans-serif;

$font-weight--base: 400;
$font-weight--headers: 900;

$text-size--small: 0.75rem; //12px
$text-size--base: 100%; // 16px
$text-size--big: 1.125rem; //18px

$rem-base: 16px;

$line-height--base: 1.45;
$line-height--headers: 1.1;


