/* ==========================================================================
   Scaffolding - Rem Calc
   ========================================================================== */

/* Strip Unit
   ========================================================================== */
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/* Convert to rem
   ========================================================================== */
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  @if ($value == 0rem) {
    $value: 0;
  }

  @return $value;
}

/* Rem Calc
   ========================================================================== */
@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();

  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }

  @return $remValues;
}
