// Hide revert to default button.
.ipe-tabs {
  [data-tab-id="revert"] {
    a {
      display: none;
    }
  }
  .ipe-tab {
    &:nth-last-child(2) {
      border-right: 1px solid darkgray;
      border-top-right-radius: 9px;
    }
  }
}

/* some updates for the panopoly widgets popup */
#modal-content {

  .form-item > label {
    font-size: 1.2em;
  }
  .form-item .form-item > label,
  .form-item > label.option {
    font-size: 1em;
  }
  .media-widget a {
    margin-right: 1em;
  }
  .form-actions {
    float: none;
    width: auto;
    text-align: left;
    margin: 1em 1em 3em 0;
    clear: both;
  }
}

/* Fixes for flexbox behavior on rows */
.panels-ipe-region {
  width: 100%;
}
.panels-ipe-editing .panels-ipe-region {
  float: none;
}

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
  }
}

.manualcrop-style-button-holder {
  display: inline-block;
}


ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
  }
}

div[onclick] {
  cursor: pointer;
}