.cke_editable {
  font-size: 16px;
  padding: 10px 15px;
  p, span, ul {
    font-size: 16px !important;
  }
  h2 {
    color: $primary-color;
    font-size: 23px;
  }
  h3 {
    color: $tertiary-color;
    font-size: 18px;
  }
  ul:not([class]) {
    margin: 5px 0 30px 16px;
    li:before {
      top: 10px;
      left: -15px;
    }
  }
  a {
    cursor: pointer;
  }
}
